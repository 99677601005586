import { default as indexX5IdYdjYF7Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/index.vue?macro=true";
import { default as mainSVLwSekjPfMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/main.vue?macro=true";
import { default as manage_45ccjFDMPfGkJdMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/manage-cc.vue?macro=true";
import { default as account4qBVU05r7FMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account.vue?macro=true";
import { default as manage_45permissionsvRYpsWmgiNMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/manage-permissions.vue?macro=true";
import { default as ordersKGXeWs0H2UMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/orders.vue?macro=true";
import { default as orderrI5tsxUkBvMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/order.vue?macro=true";
import { default as addressespic0Dz4DMdMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/addresses.vue?macro=true";
import { default as addressDBBkuk2uLTMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/address.vue?macro=true";
import { default as datafQYhy5DvmlMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/data.vue?macro=true";
import { default as reviewsSA1VMGSNvrMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/reviews.vue?macro=true";
import { default as saved_45cardsf2pw5Ss1z4Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/saved-cards.vue?macro=true";
import { default as agreementsELwmxWU1nFMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/agreements.vue?macro=true";
import { default as subscriptionISlqdp7474Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription.vue?macro=true";
import { default as subscription_45addresskqtf6wpIsJMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-address.vue?macro=true";
import { default as subscription_45products9KhQZ581PMMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-products.vue?macro=true";
import { default as subscription_45orders0wxMBoXEh8Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-orders.vue?macro=true";
import { default as cartdTxWJilLRtMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/cart.vue?macro=true";
import { default as indexCrd8OLPsLWMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/index.vue?macro=true";
import { default as databpQcPqcJGzMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/data.vue?macro=true";
import { default as addressK2meUexOvYMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/address.vue?macro=true";
import { default as shippingOuGjHOJE4fMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/shipping.vue?macro=true";
import { default as paymentBJIcZgTVCpMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/payment.vue?macro=true";
import { default as sumupruzrvFoxV7Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout/sumup.vue?macro=true";
import { default as checkoutrW3INbcGrWMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout.vue?macro=true";
import { default as checkout_45retrypkW9ITFhcSMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-retry.vue?macro=true";
import { default as checkout_45completeJg3zymujtNMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete.vue?macro=true";
import { default as checkout_45complete_45upsell5NHbTIb1gNMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete-upsell.vue?macro=true";
import { default as checkout_45confirmWeHkAVlfZ1Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm.vue?macro=true";
import { default as checkout_45confirm_45paypal5Q37Hc0RlpMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm-paypal.vue?macro=true";
import { default as re_45orderPLo0Z1BTVkMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/re-order.vue?macro=true";
import { default as product_45listCvcKUgqHdjMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/products/product-list.vue?macro=true";
import { default as product_45detailsuOkUuVVTHEMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/products/product-details.vue?macro=true";
import { default as ingredient_45listm9omsXw3EWMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/ingredients/ingredient-list.vue?macro=true";
import { default as ingredient_45detailswryCcHKD4YMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/ingredients/ingredient-details.vue?macro=true";
import { default as indexTEy3hBIbX9Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/blog/index.vue?macro=true";
import { default as categoryezt0xDbjhUMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/blog/category.vue?macro=true";
import { default as blog68RyrdxqLdMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/blog.vue?macro=true";
import { default as blog_45postojfiQT5LgEMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/blog-post.vue?macro=true";
import { default as loginwqQtVd9L5gMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/login.vue?macro=true";
import { default as registerJgt437o3nSMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/register.vue?macro=true";
import { default as forgot_45password8ywJKUygyLMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/forgot-password.vue?macro=true";
import { default as new_45passwordusiqXl7VeOMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/new-password.vue?macro=true";
import { default as contacth04Uaabx9YMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/contact.vue?macro=true";
import { default as faqtg9S6KoVUcMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/faq.vue?macro=true";
import { default as review_45form1oRrkwn3p2Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-form.vue?macro=true";
import { default as review_45statusnp4PPKVgDoMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-status.vue?macro=true";
import { default as review_45listpexLPBy1mQMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-list.vue?macro=true";
import { default as indexp8gTGjkV5WMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/index.vue?macro=true";
import { default as privacy1hW9tSdceFMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/privacy.vue?macro=true";
import { default as returnAtatGuSTrKMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/return.vue?macro=true";
import { default as payments2UZWZv16UvMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/payments.vue?macro=true";
import { default as deliveryN3rj6C2GlkMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/delivery.vue?macro=true";
import { default as mobile_45appVu4zzMztNpMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/mobile-app.vue?macro=true";
import { default as privacy_45mobile_45appLUiFGXH6o4Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/terms/privacy-mobile-app.vue?macro=true";
import { default as pracaK73QuG2yiPMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/praca.vue?macro=true";
import { default as about_45usrnpAY6MaV2Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/about-us.vue?macro=true";
import { default as cookie_45settingsa4wIfwOk4uMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/cookie-settings.vue?macro=true";
import { default as cooperationirInlC3mYtMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/cooperation.vue?macro=true";
import { default as indexvv7KhhIr0oMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/poll/index.vue?macro=true";
import { default as recommendationsPvRZpdSbX6Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/poll/recommendations.vue?macro=true";
import { default as fillP6QBySaUv1Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/poll/fill.vue?macro=true";
import { default as dashboardeFx35MkwVYMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/care-club/dashboard.vue?macro=true";
import { default as dashboardfGRfcrLEBPMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/mgm/dashboard.vue?macro=true";
import { default as recommended_45successB4m8dK4EX0Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended-success.vue?macro=true";
import { default as recommended_45failuressO8cwkBX2Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended-failure.vue?macro=true";
import { default as recommendedlw2n5qxUw8Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended.vue?macro=true";
import { default as balancegiJNbUo2u0Meta } from "/opt/atlassian/pipelines/agent/build/ui/pages/voucher/balance.vue?macro=true";
import { default as perfect_45matchNUD1K93RWNMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/perfect-match.vue?macro=true";
import { default as static_45page87bEfvgmAdMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/static-page.vue?macro=true";
import { default as _404pBBbXiHifHMeta } from "/opt/atlassian/pipelines/agent/build/ui/pages/404.vue?macro=true";
export default [
  {
    name: indexX5IdYdjYF7Meta?.name ?? "index",
    path: indexX5IdYdjYF7Meta?.path ?? "/",
    meta: {...(indexX5IdYdjYF7Meta || {}), ...{"layout":"empty"}},
    alias: indexX5IdYdjYF7Meta?.alias || [],
    redirect: indexX5IdYdjYF7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mainSVLwSekjPfMeta?.name ?? "main___pl",
    path: mainSVLwSekjPfMeta?.path ?? "/pl",
    meta: {...(mainSVLwSekjPfMeta || {}), ...{"id":"main","locale":"pl","seo":true}},
    alias: mainSVLwSekjPfMeta?.alias || [],
    redirect: mainSVLwSekjPfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/main.vue").then(m => m.default || m)
  },
  {
    name: account4qBVU05r7FMeta?.name ?? "account___pl",
    path: account4qBVU05r7FMeta?.path ?? "/pl/moje-konto",
    children: [
  {
    name: manage_45ccjFDMPfGkJdMeta?.name ?? "account-manage-cc___pl",
    path: manage_45ccjFDMPfGkJdMeta?.path ?? "/pl/moje-konto/zarzadzaj-zapisanymi-kartami",
    meta: {...(manage_45ccjFDMPfGkJdMeta || {}), ...{"id":"account-manage-cc","locale":"pl","seo":false,"layout":"empty"}},
    alias: manage_45ccjFDMPfGkJdMeta?.alias || [],
    redirect: manage_45ccjFDMPfGkJdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/manage-cc.vue").then(m => m.default || m)
  }
],
    meta: {...(account4qBVU05r7FMeta || {}), ...{"id":"account","locale":"pl","seo":false,"layout":"empty"}},
    alias: account4qBVU05r7FMeta?.alias || [],
    redirect: account4qBVU05r7FMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account.vue").then(m => m.default || m)
  },
  {
    name: manage_45permissionsvRYpsWmgiNMeta?.name ?? "account-consents___pl",
    path: manage_45permissionsvRYpsWmgiNMeta?.path ?? "/pl/moje-konto/zgody-marketingowe",
    meta: {...(manage_45permissionsvRYpsWmgiNMeta || {}), ...{"id":"account-consents","locale":"pl","seo":false,"layout":"empty"}},
    alias: manage_45permissionsvRYpsWmgiNMeta?.alias || [],
    redirect: manage_45permissionsvRYpsWmgiNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/manage-permissions.vue").then(m => m.default || m)
  },
  {
    name: ordersKGXeWs0H2UMeta?.name ?? "account-orders___pl",
    path: ordersKGXeWs0H2UMeta?.path ?? "/pl/moje-konto/zamowienia",
    meta: {...(ordersKGXeWs0H2UMeta || {}), ...{"id":"account-orders","locale":"pl","seo":false,"layout":"account"}},
    alias: ordersKGXeWs0H2UMeta?.alias || [],
    redirect: ordersKGXeWs0H2UMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orderrI5tsxUkBvMeta?.name ?? "account-order___pl",
    path: orderrI5tsxUkBvMeta?.path ?? "/pl/moje-konto/zamowienie",
    meta: {...(orderrI5tsxUkBvMeta || {}), ...{"id":"account-order","locale":"pl","seo":false,"layout":"account"}},
    alias: orderrI5tsxUkBvMeta?.alias || [],
    redirect: orderrI5tsxUkBvMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: addressespic0Dz4DMdMeta?.name ?? "account-addresses___pl",
    path: addressespic0Dz4DMdMeta?.path ?? "/pl/moje-konto/adresy",
    meta: {...(addressespic0Dz4DMdMeta || {}), ...{"id":"account-addresses","locale":"pl","seo":false,"layout":"account"}},
    alias: addressespic0Dz4DMdMeta?.alias || [],
    redirect: addressespic0Dz4DMdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressDBBkuk2uLTMeta?.name ?? "account-address___pl",
    path: addressDBBkuk2uLTMeta?.path ?? "/pl/moje-konto/adres",
    meta: {...(addressDBBkuk2uLTMeta || {}), ...{"id":"account-address","locale":"pl","seo":false,"layout":"account"}},
    alias: addressDBBkuk2uLTMeta?.alias || [],
    redirect: addressDBBkuk2uLTMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: datafQYhy5DvmlMeta?.name ?? "account-data___pl",
    path: datafQYhy5DvmlMeta?.path ?? "/pl/moje-konto/dane",
    meta: {...(datafQYhy5DvmlMeta || {}), ...{"id":"account-data","locale":"pl","seo":false,"layout":"account"}},
    alias: datafQYhy5DvmlMeta?.alias || [],
    redirect: datafQYhy5DvmlMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/data.vue").then(m => m.default || m)
  },
  {
    name: reviewsSA1VMGSNvrMeta?.name ?? "account-reviews___pl",
    path: reviewsSA1VMGSNvrMeta?.path ?? "/pl/moje-konto/opinie",
    meta: {...(reviewsSA1VMGSNvrMeta || {}), ...{"id":"account-reviews","locale":"pl","seo":false,"layout":"account"}},
    alias: reviewsSA1VMGSNvrMeta?.alias || [],
    redirect: reviewsSA1VMGSNvrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/reviews.vue").then(m => m.default || m)
  },
  {
    name: saved_45cardsf2pw5Ss1z4Meta?.name ?? "account-saved-cards___pl",
    path: saved_45cardsf2pw5Ss1z4Meta?.path ?? "/pl/moje-konto/zapisane-karty",
    meta: {...(saved_45cardsf2pw5Ss1z4Meta || {}), ...{"id":"account-saved-cards","locale":"pl","seo":false,"layout":"account"}},
    alias: saved_45cardsf2pw5Ss1z4Meta?.alias || [],
    redirect: saved_45cardsf2pw5Ss1z4Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/saved-cards.vue").then(m => m.default || m)
  },
  {
    name: agreementsELwmxWU1nFMeta?.name ?? "account-agreements___pl",
    path: agreementsELwmxWU1nFMeta?.path ?? "/pl/moje-konto/zgody",
    meta: {...(agreementsELwmxWU1nFMeta || {}), ...{"id":"account-agreements","locale":"pl","seo":false,"layout":"account"}},
    alias: agreementsELwmxWU1nFMeta?.alias || [],
    redirect: agreementsELwmxWU1nFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/agreements.vue").then(m => m.default || m)
  },
  {
    name: subscriptionISlqdp7474Meta?.name ?? "account-subscription___pl",
    path: subscriptionISlqdp7474Meta?.path ?? "/pl/moje-konto/subskrypcja",
    meta: {...(subscriptionISlqdp7474Meta || {}), ...{"id":"account-subscription","locale":"pl","seo":false,"layout":"account"}},
    alias: subscriptionISlqdp7474Meta?.alias || [],
    redirect: subscriptionISlqdp7474Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription.vue").then(m => m.default || m)
  },
  {
    name: subscription_45addresskqtf6wpIsJMeta?.name ?? "account-subscription-address___pl",
    path: subscription_45addresskqtf6wpIsJMeta?.path ?? "/pl/moje-konto/subskrypcja/adres",
    meta: {...(subscription_45addresskqtf6wpIsJMeta || {}), ...{"id":"account-subscription-address","locale":"pl","seo":false,"layout":"account"}},
    alias: subscription_45addresskqtf6wpIsJMeta?.alias || [],
    redirect: subscription_45addresskqtf6wpIsJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-address.vue").then(m => m.default || m)
  },
  {
    name: subscription_45products9KhQZ581PMMeta?.name ?? "account-subscription-products___pl",
    path: subscription_45products9KhQZ581PMMeta?.path ?? "/pl/moje-konto/subskrypcja/produkty",
    meta: {...(subscription_45products9KhQZ581PMMeta || {}), ...{"id":"account-subscription-products","locale":"pl","seo":false,"layout":"account"}},
    alias: subscription_45products9KhQZ581PMMeta?.alias || [],
    redirect: subscription_45products9KhQZ581PMMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-products.vue").then(m => m.default || m)
  },
  {
    name: subscription_45orders0wxMBoXEh8Meta?.name ?? "account-subscription-orders___pl",
    path: subscription_45orders0wxMBoXEh8Meta?.path ?? "/pl/moje-konto/subskrypcja/zamowienia",
    meta: {...(subscription_45orders0wxMBoXEh8Meta || {}), ...{"id":"account-subscription-orders","locale":"pl","seo":false,"layout":"account"}},
    alias: subscription_45orders0wxMBoXEh8Meta?.alias || [],
    redirect: subscription_45orders0wxMBoXEh8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-orders.vue").then(m => m.default || m)
  },
  {
    name: cartdTxWJilLRtMeta?.name ?? "cart___pl",
    path: cartdTxWJilLRtMeta?.path ?? "/pl/koszyk",
    meta: {...(cartdTxWJilLRtMeta || {}), ...{"id":"cart","locale":"pl","seo":false,"layout":"empty"}},
    alias: cartdTxWJilLRtMeta?.alias || [],
    redirect: cartdTxWJilLRtMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutrW3INbcGrWMeta?.name ?? "checkout___pl",
    path: checkoutrW3INbcGrWMeta?.path ?? "/pl/zamawiam",
    children: [
  {
    name: indexCrd8OLPsLWMeta?.name ?? "checkout-index___pl",
    path: indexCrd8OLPsLWMeta?.path ?? "/pl/zamawiam",
    meta: {...(indexCrd8OLPsLWMeta || {}), ...{"id":"checkout-index","locale":"pl","seo":false,"layout":"checkout"}},
    alias: indexCrd8OLPsLWMeta?.alias || [],
    redirect: indexCrd8OLPsLWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: databpQcPqcJGzMeta?.name ?? "checkout-data___pl",
    path: databpQcPqcJGzMeta?.path ?? "/pl/zamawiam/twoje-dane",
    meta: {...(databpQcPqcJGzMeta || {}), ...{"id":"checkout-data","locale":"pl","seo":false,"layout":"checkout"}},
    alias: databpQcPqcJGzMeta?.alias || [],
    redirect: databpQcPqcJGzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/data.vue").then(m => m.default || m)
  },
  {
    name: addressK2meUexOvYMeta?.name ?? "checkout-address___pl",
    path: addressK2meUexOvYMeta?.path ?? "/pl/zamawiam/adres",
    meta: {...(addressK2meUexOvYMeta || {}), ...{"id":"checkout-address","locale":"pl","seo":false,"layout":"checkout"}},
    alias: addressK2meUexOvYMeta?.alias || [],
    redirect: addressK2meUexOvYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: shippingOuGjHOJE4fMeta?.name ?? "checkout-shipping___pl",
    path: shippingOuGjHOJE4fMeta?.path ?? "/pl/zamawiam/dostawa",
    meta: {...(shippingOuGjHOJE4fMeta || {}), ...{"id":"checkout-shipping","locale":"pl","seo":false,"layout":"checkout"}},
    alias: shippingOuGjHOJE4fMeta?.alias || [],
    redirect: shippingOuGjHOJE4fMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: paymentBJIcZgTVCpMeta?.name ?? "checkout-payment___pl",
    path: paymentBJIcZgTVCpMeta?.path ?? "/pl/zamawiam/platnosc",
    meta: {...(paymentBJIcZgTVCpMeta || {}), ...{"id":"checkout-payment","locale":"pl","seo":false,"layout":"checkout"}},
    alias: paymentBJIcZgTVCpMeta?.alias || [],
    redirect: paymentBJIcZgTVCpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: sumupruzrvFoxV7Meta?.name ?? "checkout-sumup___pl",
    path: sumupruzrvFoxV7Meta?.path ?? "/pl/zamawiam/podsumowanie",
    meta: {...(sumupruzrvFoxV7Meta || {}), ...{"id":"checkout-sumup","locale":"pl","seo":false,"layout":"checkout"}},
    alias: sumupruzrvFoxV7Meta?.alias || [],
    redirect: sumupruzrvFoxV7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/sumup.vue").then(m => m.default || m)
  }
],
    meta: {...(checkoutrW3INbcGrWMeta || {}), ...{"id":"checkout","locale":"pl","seo":false,"layout":"checkout"}},
    alias: checkoutrW3INbcGrWMeta?.alias || [],
    redirect: checkoutrW3INbcGrWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout_45retrypkW9ITFhcSMeta?.name ?? "checkout-retry___pl",
    path: checkout_45retrypkW9ITFhcSMeta?.path ?? "/pl/zamawiam/ponow-platnosc",
    meta: {...(checkout_45retrypkW9ITFhcSMeta || {}), ...{"id":"checkout-retry","locale":"pl","seo":false,"layout":"checkout"}},
    alias: checkout_45retrypkW9ITFhcSMeta?.alias || [],
    redirect: checkout_45retrypkW9ITFhcSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-retry.vue").then(m => m.default || m)
  },
  {
    name: checkout_45completeJg3zymujtNMeta?.name ?? "checkout-complete___pl",
    path: checkout_45completeJg3zymujtNMeta?.path ?? "/pl/zamawiam/zamowienie-zlozone",
    meta: {...(checkout_45completeJg3zymujtNMeta || {}), ...{"id":"checkout-complete","locale":"pl","seo":false}},
    alias: checkout_45completeJg3zymujtNMeta?.alias || [],
    redirect: checkout_45completeJg3zymujtNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete.vue").then(m => m.default || m)
  },
  {
    name: checkout_45complete_45upsell5NHbTIb1gNMeta?.name ?? "checkout-complete-upsell___pl",
    path: checkout_45complete_45upsell5NHbTIb1gNMeta?.path ?? "/pl/zamawiam/zamowienie-zlozone-upsell",
    meta: {...(checkout_45complete_45upsell5NHbTIb1gNMeta || {}), ...{"id":"checkout-complete-upsell","locale":"pl","seo":false}},
    alias: checkout_45complete_45upsell5NHbTIb1gNMeta?.alias || [],
    redirect: checkout_45complete_45upsell5NHbTIb1gNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete-upsell.vue").then(m => m.default || m)
  },
  {
    name: checkout_45confirmWeHkAVlfZ1Meta?.name ?? "checkout-confirm___pl",
    path: checkout_45confirmWeHkAVlfZ1Meta?.path ?? "/pl/zamawiam/potwierdzenie",
    meta: {...(checkout_45confirmWeHkAVlfZ1Meta || {}), ...{"id":"checkout-confirm","locale":"pl","seo":false,"layout":"checkout"}},
    alias: checkout_45confirmWeHkAVlfZ1Meta?.alias || [],
    redirect: checkout_45confirmWeHkAVlfZ1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm.vue").then(m => m.default || m)
  },
  {
    name: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.name ?? "checkout-confirm-paypal___pl",
    path: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.path ?? "/pl/zamawiam/potwierdzenie-paypal",
    meta: {...(checkout_45confirm_45paypal5Q37Hc0RlpMeta || {}), ...{"id":"checkout-confirm-paypal","locale":"pl","seo":false,"layout":"checkout"}},
    alias: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.alias || [],
    redirect: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm-paypal.vue").then(m => m.default || m)
  },
  {
    name: re_45orderPLo0Z1BTVkMeta?.name ?? "re-order___pl",
    path: re_45orderPLo0Z1BTVkMeta?.path ?? "/pl/ponow-zamowienie",
    meta: {...(re_45orderPLo0Z1BTVkMeta || {}), ...{"id":"re-order","locale":"pl","seo":false,"layout":"empty"}},
    alias: re_45orderPLo0Z1BTVkMeta?.alias || [],
    redirect: re_45orderPLo0Z1BTVkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/re-order.vue").then(m => m.default || m)
  },
  {
    name: product_45listCvcKUgqHdjMeta?.name ?? "products___pl",
    path: product_45listCvcKUgqHdjMeta?.path ?? "/pl/produkty/:categoryUid?",
    meta: {...(product_45listCvcKUgqHdjMeta || {}), ...{"id":"products","locale":"pl","seo":true}},
    alias: product_45listCvcKUgqHdjMeta?.alias || [],
    redirect: product_45listCvcKUgqHdjMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/products/product-list.vue").then(m => m.default || m)
  },
  {
    name: product_45detailsuOkUuVVTHEMeta?.name ?? "product-details___pl",
    path: product_45detailsuOkUuVVTHEMeta?.path ?? "/pl/produkt/:productUid",
    meta: {...(product_45detailsuOkUuVVTHEMeta || {}), ...{"id":"product-details","locale":"pl","seo":true}},
    alias: product_45detailsuOkUuVVTHEMeta?.alias || [],
    redirect: product_45detailsuOkUuVVTHEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/products/product-details.vue").then(m => m.default || m)
  },
  {
    name: ingredient_45listm9omsXw3EWMeta?.name ?? "ingredient-list___pl",
    path: ingredient_45listm9omsXw3EWMeta?.path ?? "/pl/skladniki",
    meta: {...(ingredient_45listm9omsXw3EWMeta || {}), ...{"id":"ingredient-list","locale":"pl","seo":true}},
    alias: ingredient_45listm9omsXw3EWMeta?.alias || [],
    redirect: ingredient_45listm9omsXw3EWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/ingredients/ingredient-list.vue").then(m => m.default || m)
  },
  {
    name: ingredient_45detailswryCcHKD4YMeta?.name ?? "ingredient-details___pl",
    path: ingredient_45detailswryCcHKD4YMeta?.path ?? "/pl/skladniki/:ingredient",
    meta: {...(ingredient_45detailswryCcHKD4YMeta || {}), ...{"id":"ingredient-details","locale":"pl","seo":true}},
    alias: ingredient_45detailswryCcHKD4YMeta?.alias || [],
    redirect: ingredient_45detailswryCcHKD4YMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/ingredients/ingredient-details.vue").then(m => m.default || m)
  },
  {
    name: blog68RyrdxqLdMeta?.name ?? "blog___pl",
    path: blog68RyrdxqLdMeta?.path ?? "/pl/blog",
    children: [
  {
    name: indexTEy3hBIbX9Meta?.name ?? "blog-index___pl",
    path: indexTEy3hBIbX9Meta?.path ?? "/pl/blog",
    meta: {...(indexTEy3hBIbX9Meta || {}), ...{"id":"blog-index","locale":"pl","seo":true}},
    alias: indexTEy3hBIbX9Meta?.alias || [],
    redirect: indexTEy3hBIbX9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: categoryezt0xDbjhUMeta?.name ?? "blog-category___pl",
    path: categoryezt0xDbjhUMeta?.path ?? "/pl/blog/kategorie/:category",
    meta: {...(categoryezt0xDbjhUMeta || {}), ...{"id":"blog-category","locale":"pl","seo":true}},
    alias: categoryezt0xDbjhUMeta?.alias || [],
    redirect: categoryezt0xDbjhUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog/category.vue").then(m => m.default || m)
  }
],
    meta: {...(blog68RyrdxqLdMeta || {}), ...{"id":"blog","locale":"pl","seo":true}},
    alias: blog68RyrdxqLdMeta?.alias || [],
    redirect: blog68RyrdxqLdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: blog_45postojfiQT5LgEMeta?.name ?? "blog-post___pl",
    path: blog_45postojfiQT5LgEMeta?.path ?? "/pl/blog/:post",
    meta: {...(blog_45postojfiQT5LgEMeta || {}), ...{"id":"blog-post","locale":"pl","seo":true}},
    alias: blog_45postojfiQT5LgEMeta?.alias || [],
    redirect: blog_45postojfiQT5LgEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog-post.vue").then(m => m.default || m)
  },
  {
    name: loginwqQtVd9L5gMeta?.name ?? "login___pl",
    path: loginwqQtVd9L5gMeta?.path ?? "/pl/login",
    meta: {...(loginwqQtVd9L5gMeta || {}), ...{"id":"login","locale":"pl","seo":true}},
    alias: loginwqQtVd9L5gMeta?.alias || [],
    redirect: loginwqQtVd9L5gMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerJgt437o3nSMeta?.name ?? "register___pl",
    path: registerJgt437o3nSMeta?.path ?? "/pl/rejestracja",
    meta: {...(registerJgt437o3nSMeta || {}), ...{"id":"register","locale":"pl","seo":true}},
    alias: registerJgt437o3nSMeta?.alias || [],
    redirect: registerJgt437o3nSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/register.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password8ywJKUygyLMeta?.name ?? "forgot-password___pl",
    path: forgot_45password8ywJKUygyLMeta?.path ?? "/pl/zapomnialem-hasla",
    meta: {...(forgot_45password8ywJKUygyLMeta || {}), ...{"id":"forgot-password","locale":"pl","seo":false}},
    alias: forgot_45password8ywJKUygyLMeta?.alias || [],
    redirect: forgot_45password8ywJKUygyLMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordusiqXl7VeOMeta?.name ?? "reset-password___pl",
    path: new_45passwordusiqXl7VeOMeta?.path ?? "/pl/reset-hasla",
    meta: {...(new_45passwordusiqXl7VeOMeta || {}), ...{"id":"reset-password","locale":"pl","seo":false}},
    alias: new_45passwordusiqXl7VeOMeta?.alias || [],
    redirect: new_45passwordusiqXl7VeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordusiqXl7VeOMeta?.name ?? "new-password___pl",
    path: new_45passwordusiqXl7VeOMeta?.path ?? "/pl/nowe-haslo",
    meta: {...(new_45passwordusiqXl7VeOMeta || {}), ...{"id":"new-password","locale":"pl","seo":false}},
    alias: new_45passwordusiqXl7VeOMeta?.alias || [],
    redirect: new_45passwordusiqXl7VeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: contacth04Uaabx9YMeta?.name ?? "contact___pl",
    path: contacth04Uaabx9YMeta?.path ?? "/pl/kontakt",
    meta: {...(contacth04Uaabx9YMeta || {}), ...{"id":"contact","locale":"pl","seo":true}},
    alias: contacth04Uaabx9YMeta?.alias || [],
    redirect: contacth04Uaabx9YMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqtg9S6KoVUcMeta?.name ?? "faq___pl",
    path: faqtg9S6KoVUcMeta?.path ?? "/pl/faq",
    meta: {...(faqtg9S6KoVUcMeta || {}), ...{"id":"faq","locale":"pl","seo":true}},
    alias: faqtg9S6KoVUcMeta?.alias || [],
    redirect: faqtg9S6KoVUcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: review_45form1oRrkwn3p2Meta?.name ?? "review-form___pl",
    path: review_45form1oRrkwn3p2Meta?.path ?? "/pl/zostaw-opinie",
    meta: {...(review_45form1oRrkwn3p2Meta || {}), ...{"id":"review-form","locale":"pl","seo":false,"layout":"empty"}},
    alias: review_45form1oRrkwn3p2Meta?.alias || [],
    redirect: review_45form1oRrkwn3p2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-form.vue").then(m => m.default || m)
  },
  {
    name: review_45statusnp4PPKVgDoMeta?.name ?? "review-status___pl",
    path: review_45statusnp4PPKVgDoMeta?.path ?? "/pl/zaakceptuj-opinie",
    meta: {...(review_45statusnp4PPKVgDoMeta || {}), ...{"id":"review-status","locale":"pl","seo":false,"layout":"empty"}},
    alias: review_45statusnp4PPKVgDoMeta?.alias || [],
    redirect: review_45statusnp4PPKVgDoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-status.vue").then(m => m.default || m)
  },
  {
    name: review_45listpexLPBy1mQMeta?.name ?? "review-list___pl",
    path: review_45listpexLPBy1mQMeta?.path ?? "/pl/opinie",
    meta: {...(review_45listpexLPBy1mQMeta || {}), ...{"id":"review-list","locale":"pl","seo":true}},
    alias: review_45listpexLPBy1mQMeta?.alias || [],
    redirect: review_45listpexLPBy1mQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-list.vue").then(m => m.default || m)
  },
  {
    name: indexp8gTGjkV5WMeta?.name ?? "terms___pl",
    path: indexp8gTGjkV5WMeta?.path ?? "/pl/regulamin",
    meta: {...(indexp8gTGjkV5WMeta || {}), ...{"id":"terms","locale":"pl","seo":true}},
    alias: indexp8gTGjkV5WMeta?.alias || [],
    redirect: indexp8gTGjkV5WMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: privacy1hW9tSdceFMeta?.name ?? "terms-privacy___pl",
    path: privacy1hW9tSdceFMeta?.path ?? "/pl/polityka-prywatnosci",
    meta: {...(privacy1hW9tSdceFMeta || {}), ...{"id":"terms-privacy","locale":"pl","seo":true}},
    alias: privacy1hW9tSdceFMeta?.alias || [],
    redirect: privacy1hW9tSdceFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/privacy.vue").then(m => m.default || m)
  },
  {
    name: returnAtatGuSTrKMeta?.name ?? "terms-return___pl",
    path: returnAtatGuSTrKMeta?.path ?? "/pl/zwroty",
    meta: {...(returnAtatGuSTrKMeta || {}), ...{"id":"terms-return","locale":"pl","seo":true}},
    alias: returnAtatGuSTrKMeta?.alias || [],
    redirect: returnAtatGuSTrKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/return.vue").then(m => m.default || m)
  },
  {
    name: payments2UZWZv16UvMeta?.name ?? "terms-payments___pl",
    path: payments2UZWZv16UvMeta?.path ?? "/pl/metody-platnosci",
    meta: {...(payments2UZWZv16UvMeta || {}), ...{"id":"terms-payments","locale":"pl","seo":true}},
    alias: payments2UZWZv16UvMeta?.alias || [],
    redirect: payments2UZWZv16UvMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/payments.vue").then(m => m.default || m)
  },
  {
    name: deliveryN3rj6C2GlkMeta?.name ?? "terms-delivery___pl",
    path: deliveryN3rj6C2GlkMeta?.path ?? "/pl/metody-dostawy",
    meta: {...(deliveryN3rj6C2GlkMeta || {}), ...{"id":"terms-delivery","locale":"pl","seo":true}},
    alias: deliveryN3rj6C2GlkMeta?.alias || [],
    redirect: deliveryN3rj6C2GlkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/delivery.vue").then(m => m.default || m)
  },
  {
    name: mobile_45appVu4zzMztNpMeta?.name ?? "terms-mobile-app___pl",
    path: mobile_45appVu4zzMztNpMeta?.path ?? "/pl/regulamin-aplikacja-mobilna",
    meta: {...(mobile_45appVu4zzMztNpMeta || {}), ...{"id":"terms-mobile-app","locale":"pl","seo":true}},
    alias: mobile_45appVu4zzMztNpMeta?.alias || [],
    redirect: mobile_45appVu4zzMztNpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: privacy_45mobile_45appLUiFGXH6o4Meta?.name ?? "terms-privacy-mobile-app___pl",
    path: privacy_45mobile_45appLUiFGXH6o4Meta?.path ?? "/pl/polityka-prywatnosci-aplikacja-mobilna",
    meta: {...(privacy_45mobile_45appLUiFGXH6o4Meta || {}), ...{"id":"terms-privacy-mobile-app","locale":"pl","seo":true}},
    alias: privacy_45mobile_45appLUiFGXH6o4Meta?.alias || [],
    redirect: privacy_45mobile_45appLUiFGXH6o4Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/privacy-mobile-app.vue").then(m => m.default || m)
  },
  {
    name: pracaK73QuG2yiPMeta?.name ?? "praca___pl",
    path: pracaK73QuG2yiPMeta?.path ?? "/pl/praca",
    meta: {...(pracaK73QuG2yiPMeta || {}), ...{"id":"praca","locale":"pl","seo":true}},
    alias: pracaK73QuG2yiPMeta?.alias || [],
    redirect: pracaK73QuG2yiPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/praca.vue").then(m => m.default || m)
  },
  {
    name: about_45usrnpAY6MaV2Meta?.name ?? "about-us___pl",
    path: about_45usrnpAY6MaV2Meta?.path ?? "/pl/o-nas",
    meta: {...(about_45usrnpAY6MaV2Meta || {}), ...{"id":"about-us","locale":"pl","seo":true}},
    alias: about_45usrnpAY6MaV2Meta?.alias || [],
    redirect: about_45usrnpAY6MaV2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: cookie_45settingsa4wIfwOk4uMeta?.name ?? "cookie-settings___pl",
    path: cookie_45settingsa4wIfwOk4uMeta?.path ?? "/pl/ustawienia-cookies",
    meta: {...(cookie_45settingsa4wIfwOk4uMeta || {}), ...{"id":"cookie-settings","locale":"pl","seo":false,"layout":"empty"}},
    alias: cookie_45settingsa4wIfwOk4uMeta?.alias || [],
    redirect: cookie_45settingsa4wIfwOk4uMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cookie-settings.vue").then(m => m.default || m)
  },
  {
    name: cooperationirInlC3mYtMeta?.name ?? "cooperation___pl",
    path: cooperationirInlC3mYtMeta?.path ?? "/pl/wspolpraca",
    meta: {...(cooperationirInlC3mYtMeta || {}), ...{"id":"cooperation","locale":"pl","seo":true}},
    alias: cooperationirInlC3mYtMeta?.alias || [],
    redirect: cooperationirInlC3mYtMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cooperation.vue").then(m => m.default || m)
  },
  {
    name: indexvv7KhhIr0oMeta?.name ?? "poll-supplementation___pl",
    path: indexvv7KhhIr0oMeta?.path ?? "/pl/dobierz-suplementacje",
    meta: {...(indexvv7KhhIr0oMeta || {}), ...{"id":"poll-supplementation","locale":"pl","seo":false,"layout":"empty"}},
    alias: indexvv7KhhIr0oMeta?.alias || [],
    redirect: indexvv7KhhIr0oMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/poll/index.vue").then(m => m.default || m)
  },
  {
    name: recommendationsPvRZpdSbX6Meta?.name ?? "poll-supplementation-recommendations___pl",
    path: recommendationsPvRZpdSbX6Meta?.path ?? "/pl/wyniki-ankiety-suplementacyjnej",
    meta: {...(recommendationsPvRZpdSbX6Meta || {}), ...{"id":"poll-supplementation-recommendations","locale":"pl","seo":false}},
    alias: recommendationsPvRZpdSbX6Meta?.alias || [],
    redirect: recommendationsPvRZpdSbX6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/poll/recommendations.vue").then(m => m.default || m)
  },
  {
    name: fillP6QBySaUv1Meta?.name ?? "poll-supplementation-fill___pl",
    path: fillP6QBySaUv1Meta?.path ?? "/pl/wypelnij-ankiete-suplementacyjna",
    meta: {...(fillP6QBySaUv1Meta || {}), ...{"id":"poll-supplementation-fill","locale":"pl","seo":false,"layout":"empty"}},
    alias: fillP6QBySaUv1Meta?.alias || [],
    redirect: fillP6QBySaUv1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/poll/fill.vue").then(m => m.default || m)
  },
  {
    name: indexvv7KhhIr0oMeta?.name ?? "poll-cosmetic___pl",
    path: indexvv7KhhIr0oMeta?.path ?? "/pl/dobierz-kosmetyki",
    meta: {...(indexvv7KhhIr0oMeta || {}), ...{"id":"poll-cosmetic","locale":"pl","seo":false,"layout":"empty"}},
    alias: indexvv7KhhIr0oMeta?.alias || [],
    redirect: indexvv7KhhIr0oMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/poll/index.vue").then(m => m.default || m)
  },
  {
    name: fillP6QBySaUv1Meta?.name ?? "poll-cosmetic-fill___pl",
    path: fillP6QBySaUv1Meta?.path ?? "/pl/wypelnij-ankiete-kosmetyczna",
    meta: {...(fillP6QBySaUv1Meta || {}), ...{"id":"poll-cosmetic-fill","locale":"pl","seo":false,"layout":"empty"}},
    alias: fillP6QBySaUv1Meta?.alias || [],
    redirect: fillP6QBySaUv1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/poll/fill.vue").then(m => m.default || m)
  },
  {
    name: recommendationsPvRZpdSbX6Meta?.name ?? "poll-cosmetic-recommendations___pl",
    path: recommendationsPvRZpdSbX6Meta?.path ?? "/pl/wyniki-ankiety-kosmetycznej",
    meta: {...(recommendationsPvRZpdSbX6Meta || {}), ...{"id":"poll-cosmetic-recommendations","locale":"pl","seo":false}},
    alias: recommendationsPvRZpdSbX6Meta?.alias || [],
    redirect: recommendationsPvRZpdSbX6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/poll/recommendations.vue").then(m => m.default || m)
  },
  {
    name: dashboardeFx35MkwVYMeta?.name ?? "care-club___pl",
    path: dashboardeFx35MkwVYMeta?.path ?? "/pl/care-club",
    meta: {...(dashboardeFx35MkwVYMeta || {}), ...{"id":"care-club","locale":"pl","seo":true}},
    alias: dashboardeFx35MkwVYMeta?.alias || [],
    redirect: dashboardeFx35MkwVYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/care-club/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardfGRfcrLEBPMeta?.name ?? "mgm___pl",
    path: dashboardfGRfcrLEBPMeta?.path ?? "/pl/polecaj",
    meta: {...(dashboardfGRfcrLEBPMeta || {}), ...{"id":"mgm","locale":"pl","seo":false}},
    alias: dashboardfGRfcrLEBPMeta?.alias || [],
    redirect: dashboardfGRfcrLEBPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/mgm/dashboard.vue").then(m => m.default || m)
  },
  {
    name: recommended_45successB4m8dK4EX0Meta?.name ?? "mgm-recommended-success___pl",
    path: recommended_45successB4m8dK4EX0Meta?.path ?? "/pl/mgm/polecenie-udane",
    meta: {...(recommended_45successB4m8dK4EX0Meta || {}), ...{"id":"mgm-recommended-success","locale":"pl","seo":false}},
    alias: recommended_45successB4m8dK4EX0Meta?.alias || [],
    redirect: recommended_45successB4m8dK4EX0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended-success.vue").then(m => m.default || m)
  },
  {
    name: recommended_45failuressO8cwkBX2Meta?.name ?? "mgm-recommended-failure___pl",
    path: recommended_45failuressO8cwkBX2Meta?.path ?? "/pl/mgm/polecenie-nieudane",
    meta: {...(recommended_45failuressO8cwkBX2Meta || {}), ...{"id":"mgm-recommended-failure","locale":"pl","seo":false}},
    alias: recommended_45failuressO8cwkBX2Meta?.alias || [],
    redirect: recommended_45failuressO8cwkBX2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended-failure.vue").then(m => m.default || m)
  },
  {
    name: recommendedlw2n5qxUw8Meta?.name ?? "mgm-recommended___pl",
    path: recommendedlw2n5qxUw8Meta?.path ?? "/pl/mgm",
    meta: {...(recommendedlw2n5qxUw8Meta || {}), ...{"id":"mgm-recommended","locale":"pl","seo":false}},
    alias: recommendedlw2n5qxUw8Meta?.alias || [],
    redirect: recommendedlw2n5qxUw8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/mgm/recommended.vue").then(m => m.default || m)
  },
  {
    name: balancegiJNbUo2u0Meta?.name ?? "voucher-balance___pl",
    path: balancegiJNbUo2u0Meta?.path ?? "/pl/karta-upominkowa",
    meta: {...(balancegiJNbUo2u0Meta || {}), ...{"id":"voucher-balance","locale":"pl","seo":false}},
    alias: balancegiJNbUo2u0Meta?.alias || [],
    redirect: balancegiJNbUo2u0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/voucher/balance.vue").then(m => m.default || m)
  },
  {
    name: perfect_45matchNUD1K93RWNMeta?.name ?? "perfect-match___pl",
    path: perfect_45matchNUD1K93RWNMeta?.path ?? "/pl/perfect-match",
    meta: {...(perfect_45matchNUD1K93RWNMeta || {}), ...{"id":"perfect-match","locale":"pl","seo":true}},
    alias: perfect_45matchNUD1K93RWNMeta?.alias || [],
    redirect: perfect_45matchNUD1K93RWNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/perfect-match.vue").then(m => m.default || m)
  },
  {
    name: static_45page87bEfvgmAdMeta?.name ?? "static-page___pl",
    path: static_45page87bEfvgmAdMeta?.path ?? "/pl/:page+",
    meta: {...(static_45page87bEfvgmAdMeta || {}), ...{"id":"static-page","locale":"pl","seo":true}},
    alias: static_45page87bEfvgmAdMeta?.alias || [],
    redirect: static_45page87bEfvgmAdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/static-page.vue").then(m => m.default || m)
  },
  {
    name: mainSVLwSekjPfMeta?.name ?? "main___pl-en",
    path: mainSVLwSekjPfMeta?.path ?? "/pl-en",
    meta: {...(mainSVLwSekjPfMeta || {}), ...{"id":"main","locale":"pl-en","seo":true}},
    alias: mainSVLwSekjPfMeta?.alias || [],
    redirect: mainSVLwSekjPfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/main.vue").then(m => m.default || m)
  },
  {
    name: account4qBVU05r7FMeta?.name ?? "account___pl-en",
    path: account4qBVU05r7FMeta?.path ?? "/pl-en/my-account",
    children: [
  {
    name: manage_45ccjFDMPfGkJdMeta?.name ?? "account-manage-cc___pl-en",
    path: manage_45ccjFDMPfGkJdMeta?.path ?? "/pl-en/my-account/credit-cards",
    meta: {...(manage_45ccjFDMPfGkJdMeta || {}), ...{"id":"account-manage-cc","locale":"pl-en","seo":false,"layout":"empty"}},
    alias: manage_45ccjFDMPfGkJdMeta?.alias || [],
    redirect: manage_45ccjFDMPfGkJdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/manage-cc.vue").then(m => m.default || m)
  }
],
    meta: {...(account4qBVU05r7FMeta || {}), ...{"id":"account","locale":"pl-en","seo":false,"layout":"empty"}},
    alias: account4qBVU05r7FMeta?.alias || [],
    redirect: account4qBVU05r7FMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account.vue").then(m => m.default || m)
  },
  {
    name: manage_45permissionsvRYpsWmgiNMeta?.name ?? "account-consents___pl-en",
    path: manage_45permissionsvRYpsWmgiNMeta?.path ?? "/pl-en/my-account/marketing-consents",
    meta: {...(manage_45permissionsvRYpsWmgiNMeta || {}), ...{"id":"account-consents","locale":"pl-en","seo":false,"layout":"empty"}},
    alias: manage_45permissionsvRYpsWmgiNMeta?.alias || [],
    redirect: manage_45permissionsvRYpsWmgiNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/manage-permissions.vue").then(m => m.default || m)
  },
  {
    name: ordersKGXeWs0H2UMeta?.name ?? "account-orders___pl-en",
    path: ordersKGXeWs0H2UMeta?.path ?? "/pl-en/my-account/orders",
    meta: {...(ordersKGXeWs0H2UMeta || {}), ...{"id":"account-orders","locale":"pl-en","seo":false,"layout":"account"}},
    alias: ordersKGXeWs0H2UMeta?.alias || [],
    redirect: ordersKGXeWs0H2UMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: orderrI5tsxUkBvMeta?.name ?? "account-order___pl-en",
    path: orderrI5tsxUkBvMeta?.path ?? "/pl-en/my-account/order",
    meta: {...(orderrI5tsxUkBvMeta || {}), ...{"id":"account-order","locale":"pl-en","seo":false,"layout":"account"}},
    alias: orderrI5tsxUkBvMeta?.alias || [],
    redirect: orderrI5tsxUkBvMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: addressespic0Dz4DMdMeta?.name ?? "account-addresses___pl-en",
    path: addressespic0Dz4DMdMeta?.path ?? "/pl-en/my-account/addresses",
    meta: {...(addressespic0Dz4DMdMeta || {}), ...{"id":"account-addresses","locale":"pl-en","seo":false,"layout":"account"}},
    alias: addressespic0Dz4DMdMeta?.alias || [],
    redirect: addressespic0Dz4DMdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressDBBkuk2uLTMeta?.name ?? "account-address___pl-en",
    path: addressDBBkuk2uLTMeta?.path ?? "/pl-en/my-account/address",
    meta: {...(addressDBBkuk2uLTMeta || {}), ...{"id":"account-address","locale":"pl-en","seo":false,"layout":"account"}},
    alias: addressDBBkuk2uLTMeta?.alias || [],
    redirect: addressDBBkuk2uLTMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: datafQYhy5DvmlMeta?.name ?? "account-data___pl-en",
    path: datafQYhy5DvmlMeta?.path ?? "/pl-en/my-account/data",
    meta: {...(datafQYhy5DvmlMeta || {}), ...{"id":"account-data","locale":"pl-en","seo":false,"layout":"account"}},
    alias: datafQYhy5DvmlMeta?.alias || [],
    redirect: datafQYhy5DvmlMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/data.vue").then(m => m.default || m)
  },
  {
    name: reviewsSA1VMGSNvrMeta?.name ?? "account-reviews___pl-en",
    path: reviewsSA1VMGSNvrMeta?.path ?? "/pl-en/my-account/reviews",
    meta: {...(reviewsSA1VMGSNvrMeta || {}), ...{"id":"account-reviews","locale":"pl-en","seo":false,"layout":"account"}},
    alias: reviewsSA1VMGSNvrMeta?.alias || [],
    redirect: reviewsSA1VMGSNvrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/reviews.vue").then(m => m.default || m)
  },
  {
    name: saved_45cardsf2pw5Ss1z4Meta?.name ?? "account-saved-cards___pl-en",
    path: saved_45cardsf2pw5Ss1z4Meta?.path ?? "/pl-en/my-account/saved-cards",
    meta: {...(saved_45cardsf2pw5Ss1z4Meta || {}), ...{"id":"account-saved-cards","locale":"pl-en","seo":false,"layout":"account"}},
    alias: saved_45cardsf2pw5Ss1z4Meta?.alias || [],
    redirect: saved_45cardsf2pw5Ss1z4Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/saved-cards.vue").then(m => m.default || m)
  },
  {
    name: agreementsELwmxWU1nFMeta?.name ?? "account-agreements___pl-en",
    path: agreementsELwmxWU1nFMeta?.path ?? "/pl-en/my-account/agreements",
    meta: {...(agreementsELwmxWU1nFMeta || {}), ...{"id":"account-agreements","locale":"pl-en","seo":false,"layout":"account"}},
    alias: agreementsELwmxWU1nFMeta?.alias || [],
    redirect: agreementsELwmxWU1nFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/agreements.vue").then(m => m.default || m)
  },
  {
    name: subscriptionISlqdp7474Meta?.name ?? "account-subscription___pl-en",
    path: subscriptionISlqdp7474Meta?.path ?? "/pl-en/my-account/subscription",
    meta: {...(subscriptionISlqdp7474Meta || {}), ...{"id":"account-subscription","locale":"pl-en","seo":false,"layout":"account"}},
    alias: subscriptionISlqdp7474Meta?.alias || [],
    redirect: subscriptionISlqdp7474Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription.vue").then(m => m.default || m)
  },
  {
    name: subscription_45addresskqtf6wpIsJMeta?.name ?? "account-subscription-address___pl-en",
    path: subscription_45addresskqtf6wpIsJMeta?.path ?? "/pl-en/my-account/subscription/address",
    meta: {...(subscription_45addresskqtf6wpIsJMeta || {}), ...{"id":"account-subscription-address","locale":"pl-en","seo":false,"layout":"account"}},
    alias: subscription_45addresskqtf6wpIsJMeta?.alias || [],
    redirect: subscription_45addresskqtf6wpIsJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-address.vue").then(m => m.default || m)
  },
  {
    name: subscription_45products9KhQZ581PMMeta?.name ?? "account-subscription-products___pl-en",
    path: subscription_45products9KhQZ581PMMeta?.path ?? "/pl-en/my-account/subscription/products",
    meta: {...(subscription_45products9KhQZ581PMMeta || {}), ...{"id":"account-subscription-products","locale":"pl-en","seo":false,"layout":"account"}},
    alias: subscription_45products9KhQZ581PMMeta?.alias || [],
    redirect: subscription_45products9KhQZ581PMMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-products.vue").then(m => m.default || m)
  },
  {
    name: subscription_45orders0wxMBoXEh8Meta?.name ?? "account-subscription-orders___pl-en",
    path: subscription_45orders0wxMBoXEh8Meta?.path ?? "/pl-en/my-account/subscription/orders",
    meta: {...(subscription_45orders0wxMBoXEh8Meta || {}), ...{"id":"account-subscription-orders","locale":"pl-en","seo":false,"layout":"account"}},
    alias: subscription_45orders0wxMBoXEh8Meta?.alias || [],
    redirect: subscription_45orders0wxMBoXEh8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/account/subscription-orders.vue").then(m => m.default || m)
  },
  {
    name: cartdTxWJilLRtMeta?.name ?? "cart___pl-en",
    path: cartdTxWJilLRtMeta?.path ?? "/pl-en/cart",
    meta: {...(cartdTxWJilLRtMeta || {}), ...{"id":"cart","locale":"pl-en","seo":false,"layout":"empty"}},
    alias: cartdTxWJilLRtMeta?.alias || [],
    redirect: cartdTxWJilLRtMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutrW3INbcGrWMeta?.name ?? "checkout___pl-en",
    path: checkoutrW3INbcGrWMeta?.path ?? "/pl-en/order",
    children: [
  {
    name: indexCrd8OLPsLWMeta?.name ?? "checkout-index___pl-en",
    path: indexCrd8OLPsLWMeta?.path ?? "/pl-en/order",
    meta: {...(indexCrd8OLPsLWMeta || {}), ...{"id":"checkout-index","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: indexCrd8OLPsLWMeta?.alias || [],
    redirect: indexCrd8OLPsLWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: databpQcPqcJGzMeta?.name ?? "checkout-data___pl-en",
    path: databpQcPqcJGzMeta?.path ?? "/pl-en/order/your-data",
    meta: {...(databpQcPqcJGzMeta || {}), ...{"id":"checkout-data","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: databpQcPqcJGzMeta?.alias || [],
    redirect: databpQcPqcJGzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/data.vue").then(m => m.default || m)
  },
  {
    name: addressK2meUexOvYMeta?.name ?? "checkout-address___pl-en",
    path: addressK2meUexOvYMeta?.path ?? "/pl-en/order/address",
    meta: {...(addressK2meUexOvYMeta || {}), ...{"id":"checkout-address","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: addressK2meUexOvYMeta?.alias || [],
    redirect: addressK2meUexOvYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: shippingOuGjHOJE4fMeta?.name ?? "checkout-shipping___pl-en",
    path: shippingOuGjHOJE4fMeta?.path ?? "/pl-en/order/delivery",
    meta: {...(shippingOuGjHOJE4fMeta || {}), ...{"id":"checkout-shipping","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: shippingOuGjHOJE4fMeta?.alias || [],
    redirect: shippingOuGjHOJE4fMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: paymentBJIcZgTVCpMeta?.name ?? "checkout-payment___pl-en",
    path: paymentBJIcZgTVCpMeta?.path ?? "/pl-en/order/payment",
    meta: {...(paymentBJIcZgTVCpMeta || {}), ...{"id":"checkout-payment","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: paymentBJIcZgTVCpMeta?.alias || [],
    redirect: paymentBJIcZgTVCpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: sumupruzrvFoxV7Meta?.name ?? "checkout-sumup___pl-en",
    path: sumupruzrvFoxV7Meta?.path ?? "/pl-en/order/summary",
    meta: {...(sumupruzrvFoxV7Meta || {}), ...{"id":"checkout-sumup","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: sumupruzrvFoxV7Meta?.alias || [],
    redirect: sumupruzrvFoxV7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout/sumup.vue").then(m => m.default || m)
  }
],
    meta: {...(checkoutrW3INbcGrWMeta || {}), ...{"id":"checkout","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: checkoutrW3INbcGrWMeta?.alias || [],
    redirect: checkoutrW3INbcGrWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout_45retrypkW9ITFhcSMeta?.name ?? "checkout-retry___pl-en",
    path: checkout_45retrypkW9ITFhcSMeta?.path ?? "/pl-en/order/retry-payment",
    meta: {...(checkout_45retrypkW9ITFhcSMeta || {}), ...{"id":"checkout-retry","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: checkout_45retrypkW9ITFhcSMeta?.alias || [],
    redirect: checkout_45retrypkW9ITFhcSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-retry.vue").then(m => m.default || m)
  },
  {
    name: checkout_45completeJg3zymujtNMeta?.name ?? "checkout-complete___pl-en",
    path: checkout_45completeJg3zymujtNMeta?.path ?? "/pl-en/order/order-complete",
    meta: {...(checkout_45completeJg3zymujtNMeta || {}), ...{"id":"checkout-complete","locale":"pl-en","seo":false}},
    alias: checkout_45completeJg3zymujtNMeta?.alias || [],
    redirect: checkout_45completeJg3zymujtNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete.vue").then(m => m.default || m)
  },
  {
    name: checkout_45complete_45upsell5NHbTIb1gNMeta?.name ?? "checkout-complete-upsell___pl-en",
    path: checkout_45complete_45upsell5NHbTIb1gNMeta?.path ?? "/pl-en/order/order-complete-upsell",
    meta: {...(checkout_45complete_45upsell5NHbTIb1gNMeta || {}), ...{"id":"checkout-complete-upsell","locale":"pl-en","seo":false}},
    alias: checkout_45complete_45upsell5NHbTIb1gNMeta?.alias || [],
    redirect: checkout_45complete_45upsell5NHbTIb1gNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-complete-upsell.vue").then(m => m.default || m)
  },
  {
    name: checkout_45confirmWeHkAVlfZ1Meta?.name ?? "checkout-confirm___pl-en",
    path: checkout_45confirmWeHkAVlfZ1Meta?.path ?? "/pl-en/order/confirmation",
    meta: {...(checkout_45confirmWeHkAVlfZ1Meta || {}), ...{"id":"checkout-confirm","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: checkout_45confirmWeHkAVlfZ1Meta?.alias || [],
    redirect: checkout_45confirmWeHkAVlfZ1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm.vue").then(m => m.default || m)
  },
  {
    name: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.name ?? "checkout-confirm-paypal___pl-en",
    path: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.path ?? "/pl-en/order/confirmation-paypal",
    meta: {...(checkout_45confirm_45paypal5Q37Hc0RlpMeta || {}), ...{"id":"checkout-confirm-paypal","locale":"pl-en","seo":false,"layout":"checkout"}},
    alias: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.alias || [],
    redirect: checkout_45confirm_45paypal5Q37Hc0RlpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/checkout-confirm-paypal.vue").then(m => m.default || m)
  },
  {
    name: re_45orderPLo0Z1BTVkMeta?.name ?? "re-order___pl-en",
    path: re_45orderPLo0Z1BTVkMeta?.path ?? "/pl-en/re-order",
    meta: {...(re_45orderPLo0Z1BTVkMeta || {}), ...{"id":"re-order","locale":"pl-en","seo":false,"layout":"empty"}},
    alias: re_45orderPLo0Z1BTVkMeta?.alias || [],
    redirect: re_45orderPLo0Z1BTVkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/re-order.vue").then(m => m.default || m)
  },
  {
    name: product_45listCvcKUgqHdjMeta?.name ?? "products___pl-en",
    path: product_45listCvcKUgqHdjMeta?.path ?? "/pl-en/products/:categoryUid?",
    meta: {...(product_45listCvcKUgqHdjMeta || {}), ...{"id":"products","locale":"pl-en","seo":true}},
    alias: product_45listCvcKUgqHdjMeta?.alias || [],
    redirect: product_45listCvcKUgqHdjMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/products/product-list.vue").then(m => m.default || m)
  },
  {
    name: product_45detailsuOkUuVVTHEMeta?.name ?? "product-details___pl-en",
    path: product_45detailsuOkUuVVTHEMeta?.path ?? "/pl-en/product/:productUid",
    meta: {...(product_45detailsuOkUuVVTHEMeta || {}), ...{"id":"product-details","locale":"pl-en","seo":true}},
    alias: product_45detailsuOkUuVVTHEMeta?.alias || [],
    redirect: product_45detailsuOkUuVVTHEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/products/product-details.vue").then(m => m.default || m)
  },
  {
    name: loginwqQtVd9L5gMeta?.name ?? "login___pl-en",
    path: loginwqQtVd9L5gMeta?.path ?? "/pl-en/login",
    meta: {...(loginwqQtVd9L5gMeta || {}), ...{"id":"login","locale":"pl-en","seo":true}},
    alias: loginwqQtVd9L5gMeta?.alias || [],
    redirect: loginwqQtVd9L5gMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerJgt437o3nSMeta?.name ?? "register___pl-en",
    path: registerJgt437o3nSMeta?.path ?? "/pl-en/register",
    meta: {...(registerJgt437o3nSMeta || {}), ...{"id":"register","locale":"pl-en","seo":true}},
    alias: registerJgt437o3nSMeta?.alias || [],
    redirect: registerJgt437o3nSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/register.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password8ywJKUygyLMeta?.name ?? "forgot-password___pl-en",
    path: forgot_45password8ywJKUygyLMeta?.path ?? "/pl-en/forgot-password",
    meta: {...(forgot_45password8ywJKUygyLMeta || {}), ...{"id":"forgot-password","locale":"pl-en","seo":false}},
    alias: forgot_45password8ywJKUygyLMeta?.alias || [],
    redirect: forgot_45password8ywJKUygyLMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordusiqXl7VeOMeta?.name ?? "reset-password___pl-en",
    path: new_45passwordusiqXl7VeOMeta?.path ?? "/pl-en/reset-password",
    meta: {...(new_45passwordusiqXl7VeOMeta || {}), ...{"id":"reset-password","locale":"pl-en","seo":false}},
    alias: new_45passwordusiqXl7VeOMeta?.alias || [],
    redirect: new_45passwordusiqXl7VeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordusiqXl7VeOMeta?.name ?? "new-password___pl-en",
    path: new_45passwordusiqXl7VeOMeta?.path ?? "/pl-en/new-password",
    meta: {...(new_45passwordusiqXl7VeOMeta || {}), ...{"id":"new-password","locale":"pl-en","seo":false}},
    alias: new_45passwordusiqXl7VeOMeta?.alias || [],
    redirect: new_45passwordusiqXl7VeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: contacth04Uaabx9YMeta?.name ?? "contact___pl-en",
    path: contacth04Uaabx9YMeta?.path ?? "/pl-en/contact",
    meta: {...(contacth04Uaabx9YMeta || {}), ...{"id":"contact","locale":"pl-en","seo":true}},
    alias: contacth04Uaabx9YMeta?.alias || [],
    redirect: contacth04Uaabx9YMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqtg9S6KoVUcMeta?.name ?? "faq___pl-en",
    path: faqtg9S6KoVUcMeta?.path ?? "/pl-en/faq",
    meta: {...(faqtg9S6KoVUcMeta || {}), ...{"id":"faq","locale":"pl-en","seo":true}},
    alias: faqtg9S6KoVUcMeta?.alias || [],
    redirect: faqtg9S6KoVUcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: review_45form1oRrkwn3p2Meta?.name ?? "review-form___pl-en",
    path: review_45form1oRrkwn3p2Meta?.path ?? "/pl-en/review",
    meta: {...(review_45form1oRrkwn3p2Meta || {}), ...{"id":"review-form","locale":"pl-en","seo":false,"layout":"empty"}},
    alias: review_45form1oRrkwn3p2Meta?.alias || [],
    redirect: review_45form1oRrkwn3p2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-form.vue").then(m => m.default || m)
  },
  {
    name: review_45statusnp4PPKVgDoMeta?.name ?? "review-status___pl-en",
    path: review_45statusnp4PPKVgDoMeta?.path ?? "/pl-en/accept-review",
    meta: {...(review_45statusnp4PPKVgDoMeta || {}), ...{"id":"review-status","locale":"pl-en","seo":false,"layout":"empty"}},
    alias: review_45statusnp4PPKVgDoMeta?.alias || [],
    redirect: review_45statusnp4PPKVgDoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-status.vue").then(m => m.default || m)
  },
  {
    name: review_45listpexLPBy1mQMeta?.name ?? "review-list___pl-en",
    path: review_45listpexLPBy1mQMeta?.path ?? "/pl-en/reviews",
    meta: {...(review_45listpexLPBy1mQMeta || {}), ...{"id":"review-list","locale":"pl-en","seo":true}},
    alias: review_45listpexLPBy1mQMeta?.alias || [],
    redirect: review_45listpexLPBy1mQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-list.vue").then(m => m.default || m)
  },
  {
    name: indexp8gTGjkV5WMeta?.name ?? "terms___pl-en",
    path: indexp8gTGjkV5WMeta?.path ?? "/pl-en/terms",
    meta: {...(indexp8gTGjkV5WMeta || {}), ...{"id":"terms","locale":"pl-en","seo":true}},
    alias: indexp8gTGjkV5WMeta?.alias || [],
    redirect: indexp8gTGjkV5WMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: privacy1hW9tSdceFMeta?.name ?? "terms-privacy___pl-en",
    path: privacy1hW9tSdceFMeta?.path ?? "/pl-en/privacy-policy",
    meta: {...(privacy1hW9tSdceFMeta || {}), ...{"id":"terms-privacy","locale":"pl-en","seo":true}},
    alias: privacy1hW9tSdceFMeta?.alias || [],
    redirect: privacy1hW9tSdceFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/privacy.vue").then(m => m.default || m)
  },
  {
    name: returnAtatGuSTrKMeta?.name ?? "terms-return___pl-en",
    path: returnAtatGuSTrKMeta?.path ?? "/pl-en/returns",
    meta: {...(returnAtatGuSTrKMeta || {}), ...{"id":"terms-return","locale":"pl-en","seo":true}},
    alias: returnAtatGuSTrKMeta?.alias || [],
    redirect: returnAtatGuSTrKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/return.vue").then(m => m.default || m)
  },
  {
    name: payments2UZWZv16UvMeta?.name ?? "terms-payments___pl-en",
    path: payments2UZWZv16UvMeta?.path ?? "/pl-en/payments",
    meta: {...(payments2UZWZv16UvMeta || {}), ...{"id":"terms-payments","locale":"pl-en","seo":true}},
    alias: payments2UZWZv16UvMeta?.alias || [],
    redirect: payments2UZWZv16UvMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/payments.vue").then(m => m.default || m)
  },
  {
    name: deliveryN3rj6C2GlkMeta?.name ?? "terms-delivery___pl-en",
    path: deliveryN3rj6C2GlkMeta?.path ?? "/pl-en/delivery",
    meta: {...(deliveryN3rj6C2GlkMeta || {}), ...{"id":"terms-delivery","locale":"pl-en","seo":true}},
    alias: deliveryN3rj6C2GlkMeta?.alias || [],
    redirect: deliveryN3rj6C2GlkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/delivery.vue").then(m => m.default || m)
  },
  {
    name: pracaK73QuG2yiPMeta?.name ?? "praca___pl-en",
    path: pracaK73QuG2yiPMeta?.path ?? "/pl-en/career",
    meta: {...(pracaK73QuG2yiPMeta || {}), ...{"id":"praca","locale":"pl-en","seo":true}},
    alias: pracaK73QuG2yiPMeta?.alias || [],
    redirect: pracaK73QuG2yiPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/praca.vue").then(m => m.default || m)
  },
  {
    name: about_45usrnpAY6MaV2Meta?.name ?? "about-us___pl-en",
    path: about_45usrnpAY6MaV2Meta?.path ?? "/pl-en/aboutus",
    meta: {...(about_45usrnpAY6MaV2Meta || {}), ...{"id":"about-us","locale":"pl-en","seo":true}},
    alias: about_45usrnpAY6MaV2Meta?.alias || [],
    redirect: about_45usrnpAY6MaV2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: cookie_45settingsa4wIfwOk4uMeta?.name ?? "cookie-settings___pl-en",
    path: cookie_45settingsa4wIfwOk4uMeta?.path ?? "/pl-en/cookie-settings",
    meta: {...(cookie_45settingsa4wIfwOk4uMeta || {}), ...{"id":"cookie-settings","locale":"pl-en","seo":false,"layout":"empty"}},
    alias: cookie_45settingsa4wIfwOk4uMeta?.alias || [],
    redirect: cookie_45settingsa4wIfwOk4uMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cookie-settings.vue").then(m => m.default || m)
  },
  {
    name: cooperationirInlC3mYtMeta?.name ?? "cooperation___pl-en",
    path: cooperationirInlC3mYtMeta?.path ?? "/pl-en/cooperation",
    meta: {...(cooperationirInlC3mYtMeta || {}), ...{"id":"cooperation","locale":"pl-en","seo":true}},
    alias: cooperationirInlC3mYtMeta?.alias || [],
    redirect: cooperationirInlC3mYtMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cooperation.vue").then(m => m.default || m)
  },
  {
    name: balancegiJNbUo2u0Meta?.name ?? "voucher-balance___pl-en",
    path: balancegiJNbUo2u0Meta?.path ?? "/pl-en/voucher-balance",
    meta: {...(balancegiJNbUo2u0Meta || {}), ...{"id":"voucher-balance","locale":"pl-en","seo":false}},
    alias: balancegiJNbUo2u0Meta?.alias || [],
    redirect: balancegiJNbUo2u0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/voucher/balance.vue").then(m => m.default || m)
  },
  {
    name: static_45page87bEfvgmAdMeta?.name ?? "static-page___pl-en",
    path: static_45page87bEfvgmAdMeta?.path ?? "/pl-en/:page+",
    meta: {...(static_45page87bEfvgmAdMeta || {}), ...{"id":"static-page","locale":"pl-en","seo":true}},
    alias: static_45page87bEfvgmAdMeta?.alias || [],
    redirect: static_45page87bEfvgmAdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/static-page.vue").then(m => m.default || m)
  },
  {
    name: mainSVLwSekjPfMeta?.name ?? "main___de",
    path: mainSVLwSekjPfMeta?.path ?? "/de",
    meta: {...(mainSVLwSekjPfMeta || {}), ...{"id":"main","locale":"de","seo":true}},
    alias: mainSVLwSekjPfMeta?.alias || [],
    redirect: mainSVLwSekjPfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/main.vue").then(m => m.default || m)
  },
  {
    name: manage_45permissionsvRYpsWmgiNMeta?.name ?? "account-consents___de",
    path: manage_45permissionsvRYpsWmgiNMeta?.path ?? "/de/moje-konto/zgody-marketingowe",
    meta: {...(manage_45permissionsvRYpsWmgiNMeta || {}), ...{"id":"account-consents","locale":"de","seo":false,"layout":"empty"}},
    alias: manage_45permissionsvRYpsWmgiNMeta?.alias || [],
    redirect: manage_45permissionsvRYpsWmgiNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/manage-permissions.vue").then(m => m.default || m)
  },
  {
    name: product_45listCvcKUgqHdjMeta?.name ?? "products___de",
    path: product_45listCvcKUgqHdjMeta?.path ?? "/de/produkte/:categoryUid?",
    meta: {...(product_45listCvcKUgqHdjMeta || {}), ...{"id":"products","locale":"de","seo":true}},
    alias: product_45listCvcKUgqHdjMeta?.alias || [],
    redirect: product_45listCvcKUgqHdjMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/products/product-list.vue").then(m => m.default || m)
  },
  {
    name: product_45detailsuOkUuVVTHEMeta?.name ?? "product-details___de",
    path: product_45detailsuOkUuVVTHEMeta?.path ?? "/de/produkt/:productUid",
    meta: {...(product_45detailsuOkUuVVTHEMeta || {}), ...{"id":"product-details","locale":"de","seo":true}},
    alias: product_45detailsuOkUuVVTHEMeta?.alias || [],
    redirect: product_45detailsuOkUuVVTHEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/products/product-details.vue").then(m => m.default || m)
  },
  {
    name: blog68RyrdxqLdMeta?.name ?? "blog___de",
    path: blog68RyrdxqLdMeta?.path ?? "/de/blog",
    children: [
  {
    name: indexTEy3hBIbX9Meta?.name ?? "blog-index___de",
    path: indexTEy3hBIbX9Meta?.path ?? "/de/blog",
    meta: {...(indexTEy3hBIbX9Meta || {}), ...{"id":"blog-index","locale":"de","seo":true}},
    alias: indexTEy3hBIbX9Meta?.alias || [],
    redirect: indexTEy3hBIbX9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: categoryezt0xDbjhUMeta?.name ?? "blog-category___de",
    path: categoryezt0xDbjhUMeta?.path ?? "/de/blog/kategorie/:category",
    meta: {...(categoryezt0xDbjhUMeta || {}), ...{"id":"blog-category","locale":"de","seo":true}},
    alias: categoryezt0xDbjhUMeta?.alias || [],
    redirect: categoryezt0xDbjhUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog/category.vue").then(m => m.default || m)
  }
],
    meta: {...(blog68RyrdxqLdMeta || {}), ...{"id":"blog","locale":"de","seo":true}},
    alias: blog68RyrdxqLdMeta?.alias || [],
    redirect: blog68RyrdxqLdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: blog_45postojfiQT5LgEMeta?.name ?? "blog-post___de",
    path: blog_45postojfiQT5LgEMeta?.path ?? "/de/blog/:post",
    meta: {...(blog_45postojfiQT5LgEMeta || {}), ...{"id":"blog-post","locale":"de","seo":true}},
    alias: blog_45postojfiQT5LgEMeta?.alias || [],
    redirect: blog_45postojfiQT5LgEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/blog-post.vue").then(m => m.default || m)
  },
  {
    name: contacth04Uaabx9YMeta?.name ?? "contact___de",
    path: contacth04Uaabx9YMeta?.path ?? "/de/kontakt",
    meta: {...(contacth04Uaabx9YMeta || {}), ...{"id":"contact","locale":"de","seo":true}},
    alias: contacth04Uaabx9YMeta?.alias || [],
    redirect: contacth04Uaabx9YMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqtg9S6KoVUcMeta?.name ?? "faq___de",
    path: faqtg9S6KoVUcMeta?.path ?? "/de/faq",
    meta: {...(faqtg9S6KoVUcMeta || {}), ...{"id":"faq","locale":"de","seo":true}},
    alias: faqtg9S6KoVUcMeta?.alias || [],
    redirect: faqtg9S6KoVUcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: review_45form1oRrkwn3p2Meta?.name ?? "review-form___de",
    path: review_45form1oRrkwn3p2Meta?.path ?? "/de/eine-bewertung-hinterlassen",
    meta: {...(review_45form1oRrkwn3p2Meta || {}), ...{"id":"review-form","locale":"de","seo":false,"layout":"empty"}},
    alias: review_45form1oRrkwn3p2Meta?.alias || [],
    redirect: review_45form1oRrkwn3p2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-form.vue").then(m => m.default || m)
  },
  {
    name: review_45statusnp4PPKVgDoMeta?.name ?? "review-status___de",
    path: review_45statusnp4PPKVgDoMeta?.path ?? "/de/die-bewertung-akzeptieren",
    meta: {...(review_45statusnp4PPKVgDoMeta || {}), ...{"id":"review-status","locale":"de","seo":false,"layout":"empty"}},
    alias: review_45statusnp4PPKVgDoMeta?.alias || [],
    redirect: review_45statusnp4PPKVgDoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-status.vue").then(m => m.default || m)
  },
  {
    name: review_45listpexLPBy1mQMeta?.name ?? "review-list___de",
    path: review_45listpexLPBy1mQMeta?.path ?? "/de/kundenbewertungen",
    meta: {...(review_45listpexLPBy1mQMeta || {}), ...{"id":"review-list","locale":"de","seo":true}},
    alias: review_45listpexLPBy1mQMeta?.alias || [],
    redirect: review_45listpexLPBy1mQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/reviews/review-list.vue").then(m => m.default || m)
  },
  {
    name: indexp8gTGjkV5WMeta?.name ?? "terms___de",
    path: indexp8gTGjkV5WMeta?.path ?? "/de/regulamin",
    meta: {...(indexp8gTGjkV5WMeta || {}), ...{"id":"terms","locale":"de","seo":true}},
    alias: indexp8gTGjkV5WMeta?.alias || [],
    redirect: indexp8gTGjkV5WMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: privacy1hW9tSdceFMeta?.name ?? "terms-privacy___de",
    path: privacy1hW9tSdceFMeta?.path ?? "/de/polityka-prywatnosci",
    meta: {...(privacy1hW9tSdceFMeta || {}), ...{"id":"terms-privacy","locale":"de","seo":true}},
    alias: privacy1hW9tSdceFMeta?.alias || [],
    redirect: privacy1hW9tSdceFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/terms/privacy.vue").then(m => m.default || m)
  },
  {
    name: pracaK73QuG2yiPMeta?.name ?? "praca___de",
    path: pracaK73QuG2yiPMeta?.path ?? "/de/praca",
    meta: {...(pracaK73QuG2yiPMeta || {}), ...{"id":"praca","locale":"de","seo":true}},
    alias: pracaK73QuG2yiPMeta?.alias || [],
    redirect: pracaK73QuG2yiPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/praca.vue").then(m => m.default || m)
  },
  {
    name: about_45usrnpAY6MaV2Meta?.name ?? "about-us___de",
    path: about_45usrnpAY6MaV2Meta?.path ?? "/de/uber-uns",
    meta: {...(about_45usrnpAY6MaV2Meta || {}), ...{"id":"about-us","locale":"de","seo":true}},
    alias: about_45usrnpAY6MaV2Meta?.alias || [],
    redirect: about_45usrnpAY6MaV2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: cookie_45settingsa4wIfwOk4uMeta?.name ?? "cookie-settings___de",
    path: cookie_45settingsa4wIfwOk4uMeta?.path ?? "/de/cookie-einstellungen",
    meta: {...(cookie_45settingsa4wIfwOk4uMeta || {}), ...{"id":"cookie-settings","locale":"de","seo":false,"layout":"empty"}},
    alias: cookie_45settingsa4wIfwOk4uMeta?.alias || [],
    redirect: cookie_45settingsa4wIfwOk4uMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cookie-settings.vue").then(m => m.default || m)
  },
  {
    name: cooperationirInlC3mYtMeta?.name ?? "cooperation___de",
    path: cooperationirInlC3mYtMeta?.path ?? "/de/zusammenarbeit",
    meta: {...(cooperationirInlC3mYtMeta || {}), ...{"id":"cooperation","locale":"de","seo":true}},
    alias: cooperationirInlC3mYtMeta?.alias || [],
    redirect: cooperationirInlC3mYtMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/cooperation.vue").then(m => m.default || m)
  },
  {
    name: static_45page87bEfvgmAdMeta?.name ?? "static-page___de",
    path: static_45page87bEfvgmAdMeta?.path ?? "/de/:page+",
    meta: {...(static_45page87bEfvgmAdMeta || {}), ...{"id":"static-page","locale":"de","seo":true}},
    alias: static_45page87bEfvgmAdMeta?.alias || [],
    redirect: static_45page87bEfvgmAdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/static-page.vue").then(m => m.default || m)
  },
  {
    name: _404pBBbXiHifHMeta?.name ?? "404___pl-en",
    path: _404pBBbXiHifHMeta?.path ?? "/:slug*",
    meta: {...(_404pBBbXiHifHMeta || {}), ...{"id":"404","layout":"empty"}},
    alias: _404pBBbXiHifHMeta?.alias || [],
    redirect: _404pBBbXiHifHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/ui/pages/404.vue").then(m => m.default || m)
  }
]
import { type Image, type LinkFragment, type RichText } from '@healthlabs/prismic';

import { type ProductVariant } from './ProductVariant.js';
import { type ProductComposition } from './ProductComposition.js';

export interface ProductBase {
    old: boolean;

    uid: string;
    name: string;
    sku: string;
    careClubOnly: boolean;
    inStock: boolean;
    reviewCount: number;
    reviewScore: number | null;
    externalCheckoutLink?: {
        link: LinkFragment;
        text?: string | null;
    } | null;
    showLinkToPerfectMatchInUpsell: boolean;
}

export interface ProductCommon extends ProductBase {
    ean: string | null;
    canonicalProductUid: string | null;
    tags: ProductTag[];
    size: string | null;
    coinback?: ProductCoinback[];

    /** Regular, non discounted price */
    regularPrice: number;
    /** Final, discounted price */
    finalPrice: number;

    omnibusPrice: number;
    ext: ProductConfigurableExtension | ProductBundleExtension | ProductVirtualExtension | null;

    brief: string | null;

    packshot: Image | null;
    feedPhoto?: Image | null;
    tilePhoto: Image | null;
    shipmentAfter: string | null;
    type: ProductTypes | null;
    productMultipack: boolean | null;
    category: ProductCardCategory | null;

    productForm: number[] | null;
    productCharacter: number[] | null;
    productLine: number[] | null;
    indications: number[] | null;
    skinNeeds: number[] | null;
    treatmentDays: number | null;
    listingPhoto: Image | null;
    horizontalPhoto: Image | null;
    headerImageDesktop: Image | null;
    imageInSet?: Image | null;
    newsletterListId: string | null;
    isInPerfectMatch: boolean;
    showInProductFeed: boolean;
}
export interface ProductNew extends ProductCommon {
    old: false;

    description: string | null;
    usp: ProductCardUSP[] | null;
    accordion: ProductCardAccordion[] | null;
    alerts: ProductCardAlert[] | null;
    features: ProductCardFeatures[] | null;
    production: boolean;
    gallery: ProductCardGallery[] | null;
    googleProductCategory: number | null;
    doNotShowAsPromo: boolean;

    meta: {
        title: string;
        description: string | null;
        noIndex: boolean;
        socialImage: Image | null;
    };
    alternativeLocales: {
        locale: string;
        uid: string;
    }[];
    accentColor: string | null;
    backgroundColor: string | null;
    foregroundColor: string | null;
    upsellProductsBackgroundColor: string | null;
    variantSwitcherText: string | null;
    variants: ProductVariant[] | null;
    dosign: ProductCardDosing | null;
    composition: ProductCardComposition | null;
    upsell: ProductUpsell[];
    treatmentText: string | null;
    productSubscription: ProductCardSubscription | null;
}

export interface ProductCardSubscription {
    subscriptionBasePrice: number | null;
    percentDiscount: number | null;
    recommendedOrderFrequency: number | null;
    orderFrequencyOptions: (number | null)[];
}

export interface ProductUpsell {
    sku: string;
    comment?: string | null;
}

export interface ProductOld extends ProductCommon {
    old: true;

    description: RichText | null;
    treatmentText: RichText | null;
    descriptionsWithIcon: ProductDescriptionWithIcon[];
    categoryUid: string | null;
    /** Photo displayed on product tile */
    verticalPhoto: Image | null;
    badgeDesktop: Image | null;
    badgeMobile: Image | null;
    color: string | null;
    hideLastViewedProducts?: boolean | null;
    productSubscription?: ProductCardSubscription;
}

export interface ProductCardAccordion {
    icon: Image | null;
    heading: string | null;
    text: string | null;
}

export interface ProductCardComposition {
    icon: Image | null;
    heading: string | null;
    ingredients: ProductComposition[];
}

export interface ProductCardAlert {
    icon: Image | null;
    heading: string | null;
    text: string | null;
}

export interface ProductCardUSP {
    icon: Image | null;
    title: string | null;
}

export interface ProductCardFeatures {
    icon: Image | null;
    label: string | null;
}

export interface ProductCardDosing {
    icon: Image | null;
    heading: string | null;
    introduction: RichText | null;
    doses: {
        title: RichText | null;
        dosing: string | null;
        description: string | null;
        color: string | null;
        colorDose: string | null;
        image: Image | null;
    }[];
    disclaimer: RichText | null;
}
export interface ProductCardGallery {
    image: Image | null;
    teaser: RichText | null;
    video: string | null;
    autoplay: boolean;
    loop: boolean;
    controls: boolean;
    fullscreen?: boolean;
}

export type Product = ProductNew | ProductOld;

export interface ProductCardCategory {
    uid: string | null;
    name: string | null;
    url: string | null;
}

export type ProductExtension =
    | ProductConfigurableExtension
    | ProductBundleExtension
    | ProductVirtualExtension;

export interface ProductConfigurableExtension {
    type: 'configurable';
    variantSku: string;
}

export interface ProductBundleExtension {
    type: 'bundle';
    items: {
        id: number;
        value: string;
        uid: string;
        sku: string;
        quantity: number;
    }[];
}

export interface ProductVirtualExtension {
    type: 'virtual';
    items: {
        id: number;
        value: string;
        uid: string;
        sku: string;
        quantity: number;
    }[];
}

export interface ProductHasCategory {
    categoryId: number;
    position: number;
}

export interface ProductTag {
    name: string;
    color: string | null;
}

export interface ProductCoinback {
    level: number;
    percent: number;
    coins: number;
}

export enum ProductType {
    Supplement = 'Supplement',
    Cosmetic = 'Cosmetic',
    Virtual = 'Virtual',
    Medical = 'Medical',
    GiftCard = 'GiftCard',
    Set = 'Set',
}

export type ProductTypes = keyof typeof ProductType;

export enum PriceTypeEnum {
    promoPrice = 'promoPrice',
    codePrice = 'codePrice',
    promoCodePrice = 'promoCodePrice',
    regularPrice = 'regularPrice',
}

export interface ProductDescriptionWithIcon {
    icon: Image | null;
    text: RichText | null;
}
